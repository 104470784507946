module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}},{"resolve":"gatsby-remark-shiki-twoslash","options":{"theme":"/opt/build/repo/website/node_modules/gatsby-theme-clean-docs/src/dark-theme.json"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-clean-docs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

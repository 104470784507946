// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-md": () => import("./../src/pages/api.md" /* webpackChunkName: "component---src-pages-api-md" */),
  "component---src-pages-changelog-js": () => import("./../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-index-md": () => import("./../src/pages/index.md" /* webpackChunkName: "component---src-pages-index-md" */),
  "component---src-pages-modules-md": () => import("./../src/pages/modules.md" /* webpackChunkName: "component---src-pages-modules-md" */),
  "component---src-templates-module-readme-js": () => import("./../src/templates/ModuleReadme.js" /* webpackChunkName: "component---src-templates-module-readme-js" */)
}

